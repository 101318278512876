import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const Company = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
        <g>
            <path
                fill="none"
                d="M0 0h24v24H0z"
            />
            <path d="M21 20h2v2H1v-2h2V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v17zm-2 0V4H5v16h14zM8 11h3v2H8v-2zm0-4h3v2H8V7zm0 8h3v2H8v-2zm5 0h3v2h-3v-2zm0-4h3v2h-3v-2zm0-4h3v2h-3V7z" />
        </g>
    </svg>,
    'Company'
);

export default Company;
