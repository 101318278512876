import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import globalSlice from './globalSlice';
import { apiSlice } from './apiSlice';

const rootReducer = combineReducers({
    global: globalSlice,
    [apiSlice.reducerPath]: apiSlice.reducer
});

const middlewares = [];

// if (process.env.NODE_ENV === 'development') {
//     // eslint-disable-next-line global-require
//     const { createLogger } = require('redux-logger');
//     const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
//     middlewares.push(logger);
// }

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    }).concat(middlewares).concat(apiSlice.middleware),
    devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS === 'true'
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
