import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import api from '../services/api';
import { jwtTokenConfig } from '../config';
import { apiSlice, useLazyGetMeQuery } from '../store/accountApiSlice';
import { useDispatch } from 'react-redux';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const handlers = {
    INITIALIZE: (state, action) => {
        const { isAuthenticated, user } = action.payload;

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        };
    },
    LOGIN: (state, action) => {
        const { user } = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            user,
        };
    },
    LOGOUT: (state) => ({
        ...state,
        isAuthenticated: false,
        user: null,
    })
};

const baseURL = process.env.REACT_APP_API_URL || '';

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
    ...initialState,
    platform: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    passwordRecovery: () => Promise.resolve(),
    passwordReset: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const reduxDispatch = useDispatch();

    const [getMe] = useLazyGetMeQuery();

    const handleGetMe = async () => {
        await getMe().unwrap();
    };

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken) {
                    await handleGetMe();

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user: null,
                        },
                    });
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const login = async (email, password) => {
        const result = await api({
            ...(baseURL ? { baseURL } : {}),
            url: 'oauth/token',
            method: 'post',
            data: {
                client_id: jwtTokenConfig.clientId,
                client_secret: jwtTokenConfig.clientSecret,
                grant_type: 'password',
                scope: '*',
                username: email,
                password,
            }
        })
            .then((res) => res.data)
            .then((res) => {
                const { token_type: tokenType, expires_in: expiresIn, access_token: accessToken, refresh_token: refreshToken } = res;

                localStorage.setItem('tokenType', tokenType);
                localStorage.setItem('expiresIn', expiresIn);
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('refreshToken', refreshToken);

                return res;
            });

        if (!result) {
            return;
        }

        await handleGetMe();

        dispatch({
            type: 'LOGIN',
            payload: {
                user: null,
            },
        });
    };

    const logout = async () => {
        localStorage.removeItem('accessToken');
        reduxDispatch(apiSlice.util.resetApiState());
        dispatch({ type: 'LOGOUT' });
    };

    const passwordRecovery = async (email) => {
        await api.post('authentication/forget-password', { email })
            .then((res) => res.data);
    };

    const passwordReset = (token, email, password, passwordConfirmation) => new Promise((resolve, reject) => {
        api.post('authentication/reset-password', {
            token,
            email,
            password,
            password_confirmation: passwordConfirmation
        })
            .then((res) => res.data).then(resolve).catch(reject);
    });

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'JWT',
                login,
                logout,
                passwordRecovery,
                passwordReset,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContext;
