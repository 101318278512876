import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/ui/LoadingScreen';
import MainLayout from './components/ui/MainLayout';

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
        <Component {...props} />
    </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/account/Account')));
const CompanyEdit = Loadable(lazy(() => import('./pages/dashboard/company/CompanyEdit')));
const CompanyUserCreate = Loadable(lazy(() => import('./pages/dashboard/company/CompanyUserCreate')));
const CompanyUserEdit = Loadable(lazy(() => import('./pages/dashboard/company/CompanyUserEdit')));
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/dashboard/Dashboard')));
const Clients = Loadable(lazy(() => import('./pages/dashboard/clients/Clients')));
const ArchivedClients = Loadable(lazy(() => import('./pages/dashboard/clients/ArchivedClients')));
const ClientDetails = Loadable(lazy(() => import('./pages/dashboard/clients/ClientDetails')));

const Users = Loadable(lazy(() => import('./pages/dashboard/users/Users')));
const UserCreate = Loadable(lazy(() => import('./pages/dashboard/users/UserCreate')));
const UserEdit = Loadable(lazy(() => import('./pages/dashboard/users/UserEdit')));

const AuditProperties = Loadable(lazy(() => import('./pages/dashboard/audits/AuditProperties')));
const AuditPropertyCreate = Loadable(lazy(() => import('./pages/dashboard/audits/AuditPropertyCreate')));
const AuditPropertyEdit = Loadable(lazy(() => import('./pages/dashboard/audits/AuditPropertyEdit')));

const Audits = Loadable(lazy(() => import('./pages/dashboard/audits/Audits')));
const AuditCreate = Loadable(lazy(() => import('./pages/dashboard/audits/AuditCreate')));
const AuditView = Loadable(lazy(() => import('./pages/dashboard/audits/AuditView')));
const AuditEstimate = Loadable(lazy(() => import('./pages/dashboard/audits/AuditEstimate')));
const AuditInterfaces = Loadable(lazy(() => import('./pages/dashboard/audits/AuditInterfaces')));
const AuditInterfaceCreate = Loadable(lazy(() => import('./pages/dashboard/audits/AuditInterfaceCreate')));
const AuditInterfaceEdit = Loadable(lazy(() => import('./pages/dashboard/audits/AuditInterfaceEdit')));

const Domains = Loadable(lazy(() => import('./pages/dashboard/monitoring/Domains')));
const DomainView = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainView')));
const DomainCreate = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainCreate')));
const DomainEdit = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainEdit')));
const DomainSitemaps = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainSitemaps')));
const DomainSitemapCreate = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainSitemapCreate')));
const DomainSitemapEdit = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainSitemapEdit')));
const DomainUrls = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainUrls')));
const DomainUrlCreate = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainUrlCreate')));
const DomainUrlView = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainUrlView')));
const DomainsReports = Loadable(lazy(() => import('./pages/dashboard/monitoring/DomainsReports')));

const WcagVersions = Loadable(lazy(() => import('./pages/dashboard/wcag/WcagVersions')));
const WcagVersionCreate = Loadable(lazy(() => import('./pages/dashboard/wcag/WcagVersionCreate')));
const WcagVersionEdit = Loadable(lazy(() => import('./pages/dashboard/wcag/WcagVersionEdit')));
const WcagRules = Loadable(lazy(() => import('./pages/dashboard/wcag/WcagRules')));

const Tasks = Loadable(lazy(() => import('./pages/dashboard/tasks/Tasks')));
const TasksReports = Loadable(lazy(() => import('./pages/dashboard/tasks/TasksReports')));

const Videos = Loadable(lazy(() => import('./pages/dashboard/help/Videos')));
const HelpDesk = Loadable(lazy(() => import('./pages/dashboard/help/HelpDesk')));
const Blogs = Loadable(lazy(() => import('./pages/dashboard/help/Blogs')));

const Settings = Loadable(lazy(() => import('./pages/dashboard/settings/Settings')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
    {
        path: 'authentication',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                )
            },
            {
                path: 'login-unguarded',
                element: <Login />
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />
            },
            {
                path: 'password-reset',
                element: <PasswordReset />
            }
        ]
    },
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: '',
                element: <Dashboard />
            },
            {
                path: 'account',
                element: <Account />
            },
            {
                path: 'my-company',
                children: [
                    {
                        path: '',
                        element: <CompanyEdit />
                    },
                ]
            },
            {
                path: 'company-users',
                children: [
                    {
                        path: 'add',
                        element: <CompanyUserCreate />
                    },
                    {
                        path: ':userId/edit',
                        element: <CompanyUserEdit />
                    }
                ]
            },
            {
                path: 'audit-properties',
                children: [
                    {
                        path: '',
                        element: <AuditProperties />
                    },
                    {
                        path: 'add-property',
                        element: <AuditPropertyCreate />
                    },
                    {
                        path: ':auditPropertyId',
                        element: <AuditPropertyEdit />
                    },
                ]
            },
            {
                path: 'audits',
                children: [
                    {
                        path: '',
                        element: <Audits />
                    },
                    {
                        path: 'order-audit',
                        element: <AuditCreate />
                    },
                    {
                        path: ':auditId',
                        element: <AuditView />
                    },
                    {
                        path: ':auditId/estimate',
                        element: <AuditEstimate />
                    },
                    {
                        path: ':auditId/interfaces',
                        element: <AuditInterfaces />
                    },
                    {
                        path: ':auditId/interfaces/add-interface',
                        element: <AuditInterfaceCreate />
                    },
                    {
                        path: ':auditId/interfaces/:interfaceId',
                        element: <AuditInterfaceEdit />
                    },
                ]
            },
            {
                path: 'monitoring',
                children: [
                    {
                        path: '',
                        element: <Domains />
                    },
                    {
                        path: 'add-domain',
                        element: <DomainCreate />
                    },
                    {
                        path: ':domainId',
                        element: <DomainView />
                    },
                    {
                        path: ':domainId/edit',
                        element: <DomainEdit />
                    },
                    {
                        path: ':domainId/sitemaps',
                        element: <DomainSitemaps />
                    },
                    {
                        path: ':domainId/sitemaps/add-sitemap',
                        element: <DomainSitemapCreate />
                    },
                    {
                        path: ':domainId/sitemaps/:sitemapId',
                        element: <DomainSitemapEdit />
                    },
                    {
                        path: ':domainId/urls',
                        element: <DomainUrls />
                    },
                    {
                        path: ':domainId/urls/add-url',
                        element: <DomainUrlCreate />
                    },
                    {
                        path: ':domainId/urls/:urlId',
                        element: <DomainUrlView />
                    },
                    {
                        path: 'reports',
                        element: <DomainsReports />
                    },
                ]
            },
            {
                path: 'wcag-versions',
                children: [
                    {
                        path: '',
                        element: <WcagVersions />
                    },
                    {
                        path: 'add-version',
                        element: <WcagVersionCreate />
                    },
                    {
                        path: ':wcagVersionId',
                        element: <WcagVersionEdit />
                    },
                ]
            },
            {
                path: 'wcag-rules',
                children: [
                    {
                        path: '',
                        element: <WcagRules />
                    },
                    {
                        path: 'add-rule',
                        element: <AuditPropertyCreate />
                    },
                    {
                        path: ':ruleId',
                        element: <AuditPropertyEdit />
                    },
                ]
            },
            {
                path: 'tasks',
                children: [
                    {
                        path: '',
                        element: <Tasks />
                    },
                    {
                        path: 'reports',
                        element: <TasksReports />
                    },
                ]
            },
            {
                path: 'learning-center',
                children: [
                    {
                        path: 'videos',
                        element: <Videos />
                    },
                    {
                        path: 'help-desk',
                        element: <HelpDesk />
                    },
                    {
                        path: 'blogs',
                        element: <Blogs />
                    },
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        element: <Settings />
                    }
                ]
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        element: <Users />
                    },
                    {
                        path: 'add',
                        element: <UserCreate />
                    },
                    {
                        path: ':userId',
                        element: <UserEdit />
                    },
                ]
            },
            {
                path: 'clients',
                children: [
                    {
                        path: '',
                        element: <Clients />
                    },
                    {
                        path: 'add',
                        element: <ClientDetails />
                    },
                    {
                        path: ':clientId',
                        element: <ClientDetails />
                    },
                ]
            },
            {
                path: 'archived-clients',
                children: [
                    {
                        path: '',
                        element: <ArchivedClients />
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="/authentication/login" />,
            },
            {
                path: '401',
                element: <AuthorizationRequired />
            },
            {
                path: '404',
                element: <NotFound />
            },
            {
                path: '500',
                element: <ServerError />
            },
            {
                path: '*',
                element: <NotFound />
            }
        ]
    }
];

export default routes;
