import { apiSlice } from './apiSlice';

export const initialState = {
    me: {},
    countries: [],
    selectedCountry: {},
    states: [],
    selectedState: {},
    notification: {
        newAudits: false,
        newsAndUpdates: false,
        warnings: false,
        alerts: false,
        emailDigest: false,
    },
};

export const accountApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMe: builder.query({
            query: () => '/account',
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: ['AccountMe'],
            keepUnusedDataFor: 60 * 60,
        }),
        updateAccount: builder.mutation({
            query: (payload) => ({
                url: '/account',
                method: 'POST',
                body: payload,
                headers: {
                    'Skip-Content-Type': true,
                },
                formData: true
            }),
            invalidatesTags: ['AccountMe'],
        }),
        updatePassword: builder.mutation({
            query: (payload) => ({
                url: '/account/password',
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AccountMe'],
        }),
        getNotifications: builder.query({
            query: () => '/account/notifications',
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: ['AccountNotifications'],
        }),
        updateNotifications: builder.mutation({
            query: (payload) => ({
                url: '/account/notifications',
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ['AccountNotifications'],
        }),
        getCountries: builder.query({
            query: () => '/geolocation/countries',
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: (result) => (result
                ? [
                    ...result.map(({ id }) => ({ type: 'AccountGeolocationsCountries', id })),
                    { type: 'AccountGeolocationsCountries', id: 'LIST' }
                ]
                : [
                    { type: 'AccountGeolocationsCountries', id: 'LIST' }
                ]),
            keepUnusedDataFor: 60 * 60
        }),
        getCountry: builder.query({
            queryFn: async (id, _queryApi, _extraOptions, _baseQuery) => {
                if (id) {
                    const { data } = await _baseQuery(`geolocation/countries/${id}`);
                    return data;
                }
                return {
                    data: initialState.selectedCountry
                };
            },
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: (result, error, id) => [{ type: 'AccountGeolocationsCountries', id }],
            keepUnusedDataFor: 60 * 60
        }),
        clearCountries: builder.mutation({
            queryFn: () => ({
                data: initialState.countries
            }),
            invalidatesTags: ['AccountGeolocationsCountries'],
        }),
        getStates: builder.query({
            query: (countryId) => ({
                url: '/geolocation/states',
                params: {
                    country_id: countryId,
                }
            }),
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: (result,) => (result
                ? [
                    ...result.map(({ id }) => ({ type: 'AccountGeolocationsStates', id })),
                    { type: 'AccountGeolocationsStates', id: 'PARTIAL-LIST' }
                ]
                : [
                    { type: 'AccountGeolocationsStates', id: 'PARTIAL-LIST' }
                ]),
            keepUnusedDataFor: 60 * 60
        }),
        getState: builder.query({
            queryFn: async (id, _queryApi, _extraOptions, _baseQuery) => {
                if (id) {
                    const { data } = await _baseQuery(`geolocation/state/${id}`);
                    return data;
                }
                return {
                    data: initialState.selectedState
                };
            },
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: (result, error, id) => [{ type: 'AccountGeolocationsStates', id }],
            keepUnusedDataFor: 60 * 60
        }),
        clearStates: builder.mutation({
            queryFn: () => ({
                data: initialState.states
            }),
            invalidatesTags: ['AccountGeolocationsStates'],
        }),
    }),
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useUpdateAccountMutation,
    useUpdatePasswordMutation,
    useGetNotificationsQuery,
    useUpdateNotificationsMutation,
    useGetCountriesQuery,
    useGetCountryQuery,
    useLazyGetStatesQuery,
    useGetStateQuery,
    useClearStatesMutation,
} = accountApiSlice;

export { apiSlice };
