/* eslint-disable no-unused-vars */
import { initialState as accountInitialState, useGetMeQuery } from '../store/accountApiSlice';

const PERMISSION_LEVELS = [
    {
        id: 1,
        label: 'User',
        slug: 'user',
    },
    {
        id: 2,
        label: 'Owner',
        slug: 'owner'
    },
    {
        id: 3,
        label: 'Master Admin',
        slug: 'master_admin'
    },
    {
        id: 4,
        label: 'Accessiblu Admin',
        slug: 'accessiblu_admin'
    }
];

/** @allowedPermissions {Array<'user', 'owner', 'master_admin', 'accessiblu_admin'>} */
const usePermission = (allowedPermissions) => {
    if (!(allowedPermissions && Array.isArray(allowedPermissions) && allowedPermissions.length > 0)) {
        return false;
    }

    const { data: user = accountInitialState.me } = useGetMeQuery();

    return !!(allowedPermissions.find((item) => user.permission_level === item));
};

export default usePermission;
