import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import Loading from '../../ui/Loading';

const LoginJWT = (props) => {
    const mounted = useMounted();
    const { login } = useAuth();

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                submit: null
            }}
            validationSchema={Yup
                .object()
                .shape({
                    email: Yup
                        .string()
                        .email('The email field must be a valid email.')
                        .max(255)
                        .required('The email field is required.'),
                    password: Yup
                        .string()
                        .max(255)
                        .required('The password field is required.')
                })}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                try {
                    await login(values.email, values.password).then((res) => {
                        const { message } = res;
                        if (message) {
                            toast.success(message || '');
                        }
                    }).catch((error) => {
                        if (error && error?.response) {
                            throw error.response.data;
                        }
                    });

                    if (mounted.current) {
                        setStatus({ success: true });
                        setSubmitting(false);
                    }
                } catch (err) {
                    toast.error(err.message || '');
                    if (mounted.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <>
                    {isSubmitting && <Loading />}
                    <form
                        noValidate
                        onSubmit={handleSubmit}
                        {...props}
                    >
                        <TextField
                            autoFocus
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label="Email Address"
                            margin="normal"
                            name="email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="email"
                            value={values.email}
                            variant="outlined"
                        />
                        <TextField
                            error={Boolean(touched.password && errors.password)}
                            fullWidth
                            helperText={touched.password && errors.password}
                            label="Password"
                            margin="normal"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password}
                            variant="outlined"
                        />
                        {errors.submit && (
                            <Box sx={{ mt: 3 }}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                        <Box sx={{ mt: 2 }}>
                            <Button
                                color="primary"
                                disabled={isSubmitting}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Log In
                            </Button>
                        </Box>
                    </form>
                </>
            )}
        </Formik>
    );
};

export default LoginJWT;
