import { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import CogIcon from '../../icons/Cog';
import CompanyIcon from '../../icons/Company';
import usePermission from '../../hooks/usePermission';
import { initialState as companyInitialState, useGetCompanyQuery } from '../../store/companyApiSlice';
import { initialState as accountInitialState, useGetMeQuery } from '../../store/accountApiSlice';

const selectFullName = (data) => {
    const { first_name: firstName, last_name: lastName } = data;
    return `${firstName} ${lastName}` || '';
};

const selectAvatarUrl = (data) => {
    const { avatar_url: avatarUrl, } = data;
    return avatarUrl || '';
};

const selectCompanyId = (data) => {
    const { company_id: companyId, } = data;
    return companyId || '';
};

const AccountPopover = () => {
    const anchorRef = useRef(null);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const { data: accountMe = accountInitialState.me } = useGetMeQuery();
    const fullName = selectFullName(accountMe);
    const avatarUrl = selectAvatarUrl(accountMe);
    const companyId = selectCompanyId(accountMe);

    const { data: { company_name: companyName } = companyInitialState.company } = useGetCompanyQuery(companyId);
    const permission = usePermission(['owner', 'master_admin']);
    const isAccessibluAdminPermissionLevel = usePermission(['accessiblu_admin']);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await logout();
            navigate('/');
        } catch (err) {
            toast.error('Unable to logout.');
        }
    };

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Avatar
                    src={avatarUrl}
                    sx={{
                        height: 32,
                        width: 32
                    }}
                />
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 }
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        {fullName}
                    </Typography>
                    {companyName && !isAccessibluAdminPermissionLevel && (
                        <Typography
                            color="textSecondary"
                            variant="subtitle2"
                        >
                            {companyName}
                        </Typography>
                    )}
                </Box>
                <Divider />
                <Box sx={{ mt: 2 }}>
                    <MenuItem
                        component={RouterLink}
                        to="/dashboard/account"
                    >
                        <ListItemIcon>
                            <CogIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography
                                    color="textPrimary"
                                    variant="subtitle2"
                                >
                                    Settings
                                </Typography>
                            )}
                        />
                    </MenuItem>
                    {permission && (
                        <MenuItem
                            component={RouterLink}
                            to="/dashboard/my-company"
                        >
                            <ListItemIcon>
                                <CompanyIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={(
                                    <Typography
                                        color="textPrimary"
                                        variant="subtitle2"
                                    >
                                        My Company
                                    </Typography>
                                )}
                            />
                        </MenuItem>
                    )}
                </Box>
                <Box sx={{ p: 2 }}>
                    <Button
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                        variant="outlined"
                    >
                        Logout
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;
