import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import useTimeout from '../../hooks/useTimeout';
import { alpha, useTheme } from '@material-ui/core/styles';

function Loading({ delay, mode = 'fixed', boxProps = {}, progressProps = {} }) {
    const theme = useTheme();
    const [showLoading, setShowLoading] = useState(!delay);

    useTimeout(() => {
        setShowLoading(true);
    }, delay);

    return (
        <Box
            className={!showLoading && 'hidden'}
            sx={mode === 'fixed' ? {
                backgroundColor: alpha(theme.palette.background.paper, 0.5),
                position: 'fixed',
                zIndex: 99999,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
            } : {}}
            {...boxProps}
        >
            <CircularProgress
                color="primary"
                {...progressProps}
            />
        </Box>
    );
}

Loading.propTypes = {
    delay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    mode: PropTypes.string,
    boxProps: PropTypes.object,
    progressProps: PropTypes.object
};

Loading.defaultProps = {
    delay: false,
    mode: 'fixed',
    boxProps: {},
    progressProps: {},
};

export default Loading;
