import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseURL = process.env.REACT_APP_API_URL || '';

export const baseQuery = fetchBaseQuery({
    baseUrl: `${baseURL}/api`,
    prepareHeaders: (headers) => {
        if (!headers.has('Content-Type')) {
            if (headers.has('Skip-Content-Type')) {
                headers.delete('Skip-Content-Type');
            } else {
                headers.set('Content-Type', 'application/json');
            }
        }
        if (!headers.has('Accept')) {
            headers.set('Accept', 'application/json');
        }
        const token = localStorage.getItem('accessToken');
        if (!headers.has('Authorization') && token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery,
    tagTypes: [
        'Companies',
        'ArchivedCompanies',
        'CompanyUsers',
        'CompaniesList',
        'Users',
        'AccountMe',
        'AccountNotifications',
        'DashboardOverview',
        'DashboardBlogPosts',
        'AccountGeolocationsCountries',
        'AccountGeolocationsStates',
        'AuditProperties',
        'AuditPropertyTypes',
        'AuditListProperties',
        'Audits',
        'AuditStatuses',
        'AuditImpacts',
        'AuditInterfaces',
        'AuditInterfaceFindings',
        'Domains',
        'DomainSitemaps',
        'DomainUrls',
        'DomainUrlScans',
        'DomainUrlScansVisualized',
        'Blogs',
        'BlogCategories',
        'WcagVersions',
        'WcagRules',
        'WcagRuleList',
        'WcagTermDefinitions',
    ],
    endpoints: () => ({})
});
