import { useTheme } from '@material-ui/core/styles';
import LogoDark from '../../assets/images/logo_dark.png';
import LogoLight from '../../assets/images/logo_light.png';

const Logo = () => {
    const theme = useTheme();

    return (
        <img
            src={theme.palette.mode === 'dark' ? LogoDark : LogoLight}
            alt="Accessiblu Blu Portal"
            style={{ maxWidth: 200 }}
        />
    );
};

export default Logo;
