import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Button, Divider, Drawer, Link, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import AuditPropertyIcon from '@material-ui/icons/FormatListBulleted';
import RuleIcon from '@material-ui/icons/Rule';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import Plus from '../../icons/Plus';
import Play from '../../icons/Play';
import User from '../../icons/User';
import Company from '../../icons/Company';
import CheckCircle from '../../icons/CheckCircle';
import Cog from '../../icons/Cog';
import Logo from '../ui/Logo';
import NavSection from '../ui/NavSection';
import Scrollbar from '../ui/Scrollbar';
import usePermission from '../../hooks/usePermission';
import { initialState as companyInitialState, useGetCompanyQuery } from '../../store/companyApiSlice';
import { useGetMeQuery, initialState as accountInitialState } from '../../store/accountApiSlice';
import Calendar from '../../icons/Calendar';

const accessibluAdministratorSections = [
    {
        title: 'General',
        items: [
            {
                title: 'Dashboard',
                path: '/dashboard',
                icon: <ChartSquareBarIcon fontSize="small" />
            },
        ]
    },
    {
        title: 'Administration',
        items: [
            {
                title: 'User Management',
                path: '/dashboard/users',
                icon: <User fontSize="small" />
            },
            {
                title: 'Client Management',
                path: '/dashboard/clients',
                icon: <Company fontSize="small" />
            },
        ]
    },
    {
        title: 'Audits',
        path: 'audits',
        items: [
            {
                title: 'Audit Properties',
                path: '/dashboard/audit-properties',
                icon: <AuditPropertyIcon fontSize="small" />
            },
            {
                title: 'Audit Requests',
                path: '/dashboard/audits',
                icon: <ChartSquareBarIcon fontSize="small" />
            },

        ]
    },
    {
        title: 'Monitoring',
        path: 'dashboard',
        items: [
            {
                title: 'Monitoring Management',
                path: '/dashboard/monitoring',
                icon: <ClipboardListIcon fontSize="small" />
            },

        ]
    },
    {
        title: 'WCAG',
        items: [
            {
                title: 'Versions',
                path: '/dashboard/wcag-versions',
                icon: <AuditPropertyIcon fontSize="small" />
            },
            {
                title: 'Rules',
                path: '/dashboard/wcag-rules',
                icon: <RuleIcon fontSize="small" />
            },

        ]
    },
    {
        title: 'Tasks',
        items: [
            {
                title: 'Task Manager',
                path: '/dashboard/tasks',
                icon: <CheckCircle fontSize="small" />
            },
            {
                title: 'Reports',
                path: '/dashboard/tasks/reports',
                icon: <ReceiptIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Learning Center',
        items: [
            {
                title: 'Videos',
                path: '/dashboard/learning-center/videos',
                icon: <Play fontSize="small" />
            },
            {
                title: 'Accessiblü Blogs',
                path: '/dashboard/learning-center/blogs',
                icon: <Calendar fontSize="small" />
            },
            {
                title: 'Help Desk',
                path: '/dashboard/learning-center/help-desk',
                icon: <ChatAltIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Settings',
        items: [
            {
                title: 'Settings',
                path: '/dashboard/settings',
                icon: <Cog fontSize="small" />
            },
        ]
    }
];

const sections = [
    {
        title: 'General',
        items: [
            {
                title: 'Dashboard',
                path: '/dashboard',
                icon: <ChartSquareBarIcon fontSize="small" />
            },
        ]
    },
    {
        title: 'Audits',
        path: 'audits',
        items: [
            {
                title: 'My Properties',
                path: '/dashboard/audit-properties',
                icon: <AuditPropertyIcon fontSize="small" />
            },
            {
                title: 'My Audits',
                path: '/dashboard/audits',
                icon: <ChartSquareBarIcon fontSize="small" />
            },
            {
                title: 'Order New Audit',
                path: '/dashboard/audits/order-audit',
                icon: <Plus fontSize="small" />
            }
        ]
    },
    {
        title: 'Monitoring',
        path: 'dashboard',
        items: [
            {
                title: 'View Domains',
                path: '/dashboard/monitoring',
                icon: <ClipboardListIcon fontSize="small" />
            },
            {
                title: 'Reports',
                path: '/dashboard/monitoring/reports',
                icon: <ReceiptIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'WCAG',
        items: [
            {
                title: 'Versions',
                path: '/dashboard/wcag-versions',
                icon: <AuditPropertyIcon fontSize="small" />
            },
            {
                title: 'Rules',
                path: '/dashboard/wcag-rules',
                icon: <RuleIcon fontSize="small" />
            },

        ]
    },
    {
        title: 'Tasks',
        items: [
            {
                title: 'Task Manager',
                path: '/dashboard/tasks',
                icon: <CheckCircle fontSize="small" />
            },
            {
                title: 'Reports',
                path: '/dashboard/tasks/reports',
                icon: <ReceiptIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Learning Center',
        items: [
            {
                title: 'Videos',
                path: '/dashboard/learning-center/videos',
                icon: <Play fontSize="small" />
            },
            {
                title: 'Accessiblü Blogs',
                path: '/dashboard/learning-center/blogs',
                icon: <Calendar fontSize="small" />
            },
            {
                title: 'Help Desk',
                path: '/dashboard/learning-center/help-desk',
                icon: <ChatAltIcon fontSize="small" />
            }
        ]
    },
    {
        title: 'Settings',
        items: [
            {
                title: 'Settings',
                path: '/dashboard/settings',
                icon: <Cog fontSize="small" />
            },
        ]
    }
];
const selectFullName = (data) => {
    const { first_name: firstName, last_name: lastName } = data;
    return `${firstName} ${lastName}` || '';
};

const selectAvatarUrl = (data) => {
    const { avatar_url: avatarUrl, } = data;
    return avatarUrl || '';
};

const selectCompanyId = (data) => {
    const { company_id: companyId, } = data;
    return companyId || '';
};

/*
// example v2 - selecting data via rtk api.
const companyId = useSelector(selectCompanyId);
const selectCompanyId = (state) => {
    const result = apiSlice.endpoints.getMe.select()(state);
    const { data } = result;
    const { company_id: companyId } = data;
    return companyId || '';
};
*/

const DashboardSidebar = (props) => {
    const { onMobileClose, openMobile } = props;
    const location = useLocation();
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const { data: accountMe = accountInitialState.me } = useGetMeQuery();
    const fullName = selectFullName(accountMe);
    const avatarUrl = selectAvatarUrl(accountMe);
    const companyId = selectCompanyId(accountMe);
    const { data: { company_name: companyName } = companyInitialState.company } = useGetCompanyQuery(companyId);
    const isAccessibluAdminPermissionLevel = usePermission(['accessiblu_admin']);
    const sidebarSections = isAccessibluAdminPermissionLevel ? accessibluAdministratorSections : sections;

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <Scrollbar options={{ suppressScrollX: true }}>
                <Box
                    sx={{
                        display: {
                            lg: 'none',
                            xs: 'flex'
                        },
                        justifyContent: 'center',
                        p: 2
                    }}
                >
                    <RouterLink to="/">
                        <Logo
                            sx={{
                                height: 40,
                                width: 40
                            }}
                        />
                    </RouterLink>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            backgroundColor: 'background.default',
                            borderRadius: 1,
                            display: 'flex',
                            overflow: 'hidden',
                            p: 2
                        }}
                    >
                        <RouterLink to="/dashboard/account">
                            <Avatar
                                src={avatarUrl}
                                sx={{
                                    cursor: 'pointer',
                                    height: 48,
                                    width: 48
                                }}
                            />
                        </RouterLink>
                        <Box sx={{ ml: 2 }}>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                {fullName}
                            </Typography>
                            {companyName && !isAccessibluAdminPermissionLevel && (

                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    Company:
                                    {' '}
                                    <Link
                                        color="primary"
                                        component={RouterLink}
                                        to="#"
                                    >
                                        {companyName}
                                    </Link>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    {sidebarSections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3
                                }
                            }}
                            {...section}
                        />
                    ))}
                </Box>
                <Divider />
                <Box sx={{ p: 2 }}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        Need Help?
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        Check our docs
                    </Typography>
                    <Button
                        color="primary"
                        component={RouterLink}
                        fullWidth
                        sx={{ mt: 2 }}
                        to="/docs"
                        variant="contained"
                    >
                        Documentation
                    </Button>
                </Box>
            </Scrollbar>
        </Box>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'background.paper',
                        height: 'calc(100% - 64px) !important',
                        top: '64px !Important',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{
                sx: {
                    backgroundColor: 'background.paper',
                    width: 280
                }
            }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default DashboardSidebar;
