import { apiSlice } from './apiSlice';

export const initialState = {
    companies: {
        data: [],
        pagination: {}
    },
    company: {
        id: 0,
        company_name: '',
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        type_id: '',
        monitoring: false,
        restricted_domains_count: '',
        restricted_urls_count: '',
        created_at: '',
        updated_at: ''
    },
    users: [],
    userInfo: {}
};

export const companyApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompanies: builder.query({
            query: (args) => ({
                url: '/companies',
                params: {
                    ...args,
                }
            }),
            transformResponse: (response) => ({
                data: response.data,
                pagination: response.meta,
            }),
            providesTags: (result) => (result
                ? [
                    ...result.data.map(({ id }) => ({ type: 'Companies', id })),
                    { type: 'Companies', id: 'LIST' },
                    { type: 'Companies', id: 'PARTIAL-LIST' },
                ]
                : [
                    { type: 'Companies', id: 'LIST' },
                    { type: 'Companies', id: 'PARTIAL-LIST' }
                ]),
        }),
        getArchivedCompanies: builder.query({
            query: (args) => ({
                url: '/archived-companies',
                params: {
                    ...args,
                }
            }),
            transformResponse: (response) => ({
                data: response.data,
                pagination: response.meta,
            }),
            providesTags: (result) => (result
                ? [
                    ...result.data.map(({ id }) => ({ type: 'ArchivedCompanies', id })),
                    { type: 'ArchivedCompanies', id: 'LIST' },
                    { type: 'ArchivedCompanies', id: 'PARTIAL-LIST' },
                ]
                : [
                    { type: 'ArchivedCompanies', id: 'LIST' },
                    { type: 'ArchivedCompanies', id: 'PARTIAL-LIST' }
                ]),
        }),
        getCompany: builder.query({
            queryFn: async (id, _queryApi, _extraOptions, _baseQuery) => {
                if (id) {
                    const { data } = await _baseQuery(`/companies/${id}`);
                    return data;
                }
                return {
                    data: initialState.company
                };
            },
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: (result, error, id) => [{ type: 'Companies', id }],
        }),
        createCompany: builder.mutation({
            query: (payload) => ({
                url: '/companies',
                method: 'POST',
                body: payload
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: [
                { type: 'Companies', id: 'LIST' },
                { type: 'Companies', id: 'PARTIAL-LIST' }
            ],
        }),
        updateCompany: builder.mutation({
            query: ({ id, ...payload }) => ({
                url: `/companies/${id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Companies', id: arg.id },
                { type: 'Companies', id: 'LIST' },
                { type: 'Companies', id: 'PARTIAL-LIST' },
            ],
        }),
        deleteCompany: builder.mutation({
            query: (id) => ({
                url: `/companies/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [
                { type: 'Companies', id },
                { type: 'Companies', id: 'LIST' },
                { type: 'Companies', id: 'PARTIAL-LIST' }
            ],
        }),
        getCompanyUsers: builder.query({
            query: (args) => ({
                url: `/companies/${args.companyId}/users`,
                params: {
                    ...args,
                }
            }),
            transformResponse: (response) => ({
                data: response.data,
                pagination: response.meta,
            }),
            providesTags: (result) => (result
                ? [
                    ...result.data.map(({ id }) => ({ type: 'CompanyUsers', id })),
                    { type: 'CompanyUsers', id: 'LIST' },
                ]
                : [
                    { type: 'CompanyUsers', id: 'LIST' },
                ]),
        }),
        createCompanyUser: builder.mutation({
            query: (payload) => ({
                url: `/companies/${payload.company_id}/users`,
                method: 'POST',
                body: payload
            }),
            invalidatesTags: [{ type: 'CompanyUsers', id: 'LIST' }],
        }),
        getCompanyUser: builder.query({
            query: (payload) => ({
                url: `/companies/${payload.companyId}/users/${payload.userId}`,
            }),
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: (result, error, payload) => [{ type: 'CompanyUsers', id: payload.userId }],
        }),
        updateCompanyUser: builder.mutation({
            query: (payload) => ({
                url: `/companies/${payload.company_id}/users/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, payload) => [
                { type: 'CompanyUsers', id: payload.id },
                { type: 'CompanyUsers', id: 'LIST' }
            ],
        }),
        deleteCompanyUser: builder.mutation({
            query: (payload) => ({
                url: `/companies/${payload.companyId}/users/${payload.userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, payload) => [
                { type: 'CompanyUsers', id: payload.userId },
                { type: 'CompanyUsers', id: 'LIST' }
            ],
        }),
        getCompaniesList: builder.query({
            query: () => '/companies/list',
            transformResponse: (response) => {
                const { data } = response;
                return data;
            },
            providesTags: ['CompaniesList'],
            keepUnusedDataFor: 60 * 60 * 24
        }),
    }),
});

export const {
    useLazyGetCompaniesQuery,
    useLazyGetArchivedCompaniesQuery,
    useGetCompanyQuery,
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
    useGetCompanyUsersQuery,
    useLazyGetCompanyUsersQuery,
    useCreateCompanyUserMutation,
    useLazyGetCompanyUserQuery,
    useGetCompaniesListQuery,
    useUpdateCompanyUserMutation,
    useDeleteCompanyUserMutation,
} = companyApiSlice;

export { apiSlice };
