import { Box } from '@material-ui/core';

const LoadingScreen = () => (
    <Box
        sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%'
        }}
    />
);

export default LoadingScreen;
