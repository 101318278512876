import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        loading: false,
        error: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.includes('/pending') && action.type.includes(':load'),
                (state) => {
                    state.loading = true;
                    state.error = null;
                },
            )
            .addMatcher(
                (action) => action.type.includes('/fulfilled') && action.type.includes(':load'),
                (state) => {
                    state.loading = false;
                    state.error = null;
                },
            )
            .addMatcher(
                (action) => action.type.includes('/rejected') && action.type.includes(':load'),
                (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                },
            );
    },
});

export const { setLoading, setError } = globalSlice.actions;

const { reducer } = globalSlice;
export default reducer;
